import React from "react";

function SiAboutUs() {
  return (
    <div class="bg-gray-100">
      <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">ScammersLK ගැන</h1>

        <p class="mb-4">අවසන් වරට යාවත්කාලීන කළේ: 1/15/2024</p>

        <p class="mb-4">
          ScammersLK වෙත සාදරයෙන් පිළිගනිමු, සැක සහිත වංචාකරුවන් පිළිබඳ තොරතුරු
          හෙළිදරව් කිරීමට සහ බෙදා ගැනීමට කැප වූ ඔබේ ප්‍රජාව විසින් මෙහෙයවන
          වේදිකාව. අපගේ මෙහෙවර වන්නේ පුද්ගලයන්ට නිර්නාමිකව දායක විය හැකි ඉඩක්
          ලබා දීමෙන් වංචා කරුවන්ට විරුද්දව ඔවුන් සවිබල ගැන්වීම සහ වංචාවන්
          සම්බන්ද තොරතුරු ප්‍රජාවට ලබා දීමයි.
        </p>

        <h2 class="text-2xl font-bold mb-2"> අපගේ අරමුණ</h2>

        <p class="mb-4">
          ScammersLK යනු වංචාවන් පිළිබඳව දැනුවත් කිරීමට සහ අනාවරණය කර ගැනීමට
          සහයෝගීව එකට වැඩ කිරීමට පරිශීලකයින් සඳහා නිර්මාණය කරන ලද වේදිකාවකි.
          වංචනික ක්‍රියාකාරකම් වලින් එකිනෙකා ආරක්ෂා කර ගැනීම සඳහා ප්‍රජාව විසින්
          මෙහෙයවනු ලබන ප්‍රයත්නවල ශක්තිය අපි විශ්වාස කරමු.
        </p>

        <h2 class="text-2xl font-bold mb-2">එය ක්‍රියාකරන්නේ කෙසේද?</h2>

        <p class="mb-4">
          පරිශීලකයින්ට වංචාවන් සම්බන්ද නම්, සම්බන්ධතා තොරතුරු සහ වෙනත් අදාළ
          තොරතුරු ඇතුළුව වංචාකරුවන් පිළිබඳ තොරතුරු නිර්නාමිකව ඉදිරිපත් කළ හැකිය.
          මෙම තොරතුරු පසුව අපගේ වෙබ් අඩවියේ ප්‍රසිද්ධියේ ලබා ගත හැකි අතර, අනෙක්
          අය දැනුවත් කිරීමට සහ තවත් කෙනෙක් හට එම වංචාකරුවන්ගෙන් ආරක්ෂා වීමට ඉඩ
          සලසයි.
        </p>

        <h2 class="text-2xl font-bold mb-2">
          ප්‍රජාව විසින් මෙහෙයවන විනිවිදභාවය
        </h2>

        <p class="mb-4">
          ScammersLK විනිවිදභාවය සහ ප්‍රජා සහයෝගය යන මූලධර්මය මත ක්‍රියාත්මක වේ.
          ඔවුන් ඉදිරිපත් කරන තොරතුරුවල නිරවද්‍යතාවය සහ නීත්‍යානුකූලභාවය සහතික
          කරමින් වගකීමෙන් දායක වීමට අපි පරිශීලකයින් දිරිමත් කරමු. තොරතුරු
          සාමූහිකව බෙදාගැනීමෙන්, අපි සෑම කෙනෙකුටම ආරක්ෂිත සබැඳි පරිසරයක්
          නිර්මාණය කිරීමට ඉලක්ක කරමු.
        </p>

        <h2 class="text-2xl font-bold mb-2">නීතිමය වියාචනය</h2>

        <p class="mb-4">
          අපි පරිශීලකයින්ට තොරතුරු බෙදා ගැනීමට වේදිකාවක් සපයන අතර, වෙබ් අඩවියේ
          පළ කර ඇති අන්තර්ගතයේ නිරවද්‍යතාවය, සම්පූර්ණත්වය හෝ නීත්‍යානුකූලභාවය
          පිළිබඳ වගකීම ScammersLK වන අප නොගන්නා බව සැලකිල්ලට ගැනීම වැදගත්ය. අපගේ
          ප්‍රතිපත්ති උල්ලංඝනය කරන අන්තර්ගතය ඉවත් කිරීමට හෝ සංස්කරණය කිරීමට අපට
          අයිතියක් ඇත.
        </p>

        <h2 class="text-2xl font-bold mb-2">අපව අමතන්න</h2>

        <p class="mb-4">
          ඔබගේ ප්‍රතිපෝෂණය සහ මැදිහත්වීම අපගේ වේදිකාවේ සාර්ථකත්වයට ඉතා වැදගත්
          වේ. ඔබට කිසියම් ප්‍රශ්න, ගැටළු හෝ යෝජනා තිබේ නම්, scammerslk@gmail.com
          මගින් ඕනෑම වේලාවක ඔබට සම්බන්ද විය හැක.
        </p>
      </div>
    </div>
  );
}

export default SiAboutUs;
