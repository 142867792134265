import React from "react";
import Logo from "../images/logo.png";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const language = localStorage.getItem("language");

  const translations = {
    home: {
      en: "Home",
      si: "මුල් පිටුව",
    },
    myPosts: {
      en: "My Posts",
      si: "මගේ පළකිරීම්",
    },
    addNew: {
      en: "Add New",
      si: "නව එකතුවක්",
    },
    logout: {
      en: "Logout",
      si: "ඉවත්වීම",
    },
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <style>
        {`
          @media (max-width: 767px) {
            .displayName {
              display: none;
            }
          }
        `}
      </style>
      <nav className="bg-black border-gray-200 dark:bg-gray-900">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={Logo} className="h-8" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-white">
              ScammersLK
            </span>
          </Link>
          <div className="flex items-center space-x-6 rtl:space-x-reverse">
            <p className="text-sm text-white dark:text-white">
              <h2 className="displayName">{user && user.displayName}</h2>
            </p>
            <button
              className="bg-yellow-800 hover:bg-red-700 text-white px-4 rounded-full"
              onClick={handleLogout}
            >
              {translations.logout[language]}
            </button>
          </div>
        </div>
      </nav>
      <nav className="bg-gray-700 dark:bg-gray-700">
        <div className="max-w-screen-xl px-4 py-3 mx-auto">
          <div className="flex items-center">
            <ul className="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
              <li>
                <Link
                  to="/"
                  className="text-white dark:text-white hover:underline"
                  aria-current="page"
                >
                  {translations.home[language]}
                </Link>
              </li>
              <li>
                <Link
                  to="/myscam"
                  className="text-white dark:text-white hover:underline"
                  aria-current="page"
                >
                  {translations.myPosts[language]}
                </Link>
              </li>
              <li>
                <Link
                  to="/insert"
                  className="text-white dark:text-white hover:underline"
                >
                  {translations.addNew[language]}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
