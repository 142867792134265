import React, { useState } from "react";
import GoogleButton from "react-google-button";
import { auth, googleAuthProvider } from "../../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";

function Login() {
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
  };

  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      console.log(result);

      const expirationTime = new Date().getTime() + 8 * 60 * 60 * 1000;

      localStorage.setItem("token", result.user.accessToken);
      localStorage.setItem("user", JSON.stringify(result.user));
      localStorage.setItem("language", selectedLanguage); // Store selected language
      localStorage.setItem("tokenExpiration", expirationTime.toString());
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4">
      <div className="text-center">
        <img
          className="inline-block h-20 mr-4 rounded-full bg-gray-500"
          src={Logo}
          alt="Google Logo"
        />
        <h2 className="text-4xl font-bold mb-6">Welcome To ScammersLK</h2>
        <br />
        <h2 className="text-2xl mb-1">Language / භාශාව</h2>

        {/* Language dropdown */}
        <select
          value={selectedLanguage}
          onChange={handleLanguageChange}
          className="mb-10 text-xl px-8 py-3"
        >
          <option value="en">English</option>
          <option value="si">සිංහල</option>
        </select>

        <GoogleButton
          onClick={handleSignInWithGoogle}
          className="w-64 mx-auto"
        />
      </div>
    </div>
  );
}

export default Login;
