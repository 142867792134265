import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const language = localStorage.getItem("language");
  const privacyLink = language === "en" ? "/privacy" : "/sinhala/privacy";
  const aboutLink = language === "en" ? "/about" : "/sinhala/about";

  const translations = {
    about: {
      en: "About",
      si: "අප ගැන",
    },
    privacy: {
      en: "Privacy Policy",
      si: "රහස්‍යතා ප්‍රතිපත්තිය",
    },
    contact: {
      en: "Contact",
      si: "අමතන්න",
    },
    rights: {
      en: "All Rights Reserved.",
      si: "සියලු හිමිකම් ඇවිරිණි.",
    },
  };

  return (
    <footer className="bg-gray-800 w-full shadow dark:bg-gray-800 ">
      <div className="w-full mx-auto p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-400 sm:text-center dark:text-gray-400">
          © 2024{" "}
          <Link to="/" className="hover:underline">
            ScammersLK
          </Link>
          . {translations.rights[language]}.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-400 dark:text-gray-400 sm:mt-0">
          <li>
            <Link to={aboutLink} className="hover:underline me-4 md:me-6">
              {translations.about[language]}
            </Link>
          </li>
          <li>
            <Link to={privacyLink} className="hover:underline me-4 md:me-6">
              {translations.privacy[language]}
            </Link>
          </li>
          <li>
            <a
              href="mailto:scammerslk@gmail.com"
              className="me-4 hover:underline"
            >
              {translations.contact[language]}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
