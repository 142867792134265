import React, { useState } from "react";
import PropTypes from "prop-types";
import "../css/Popup.css"; // Import your CSS file for styling

function Popup({ scammer, closePopup }) {
  const language = localStorage.getItem("language");
  const translations = {
    scamtype: {
      en: "Scam Type",
      si: "වංචාවේ ස්වභාවය",
    },
    contact: {
      en: "Scammer's Phone/Email/Website",
      si: "වංචාකාරුගේ දුරකථන අංකය/ඊමේල්/වෙබ් අඩවිය",
    },
    description: {
      en: "Scam Description",
      si: "වංචාවේ විස්තරය",
    },
    confirm: {
      en: "Confirmation Picture",
      si: "සනාථ කිරීමේ පින්තූරය",
    },
    close: {
      en: "Close",
      si: "ආපසු",
    },
  };

  const [isImagePopupOpen, setImagePopupOpen] = useState(false);

  const openImagePopup = () => {
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h2 className="text-2xl font-bold mb-2">{scammer.name}</h2>
        <p className="mb-2 text-xl">
          {translations.scamtype[language]}: {scammer.type}
        </p>
        <p className="mb-2 text-xl">
          {translations.contact[language]}: <p>{scammer.contact}</p>
        </p>
        <h2 className="text-2xl font-bold mb-2">
          {translations.description[language]}
        </h2>
        <p className="mb-4">{scammer.description}</p>
        <h2 className="text-2xl font-bold mb-2">
          {translations.confirm[language]}
        </h2>
        <img
          onClick={openImagePopup}
          className="w-full h-auto mb-4 rounded-md object-cover cursor-pointer"
          src={scammer.img}
          alt="Scammer"
          id="pimg"
        />

        {isImagePopupOpen && (
          <div className="image-popup">
            <div className="image-popup-content">
              <img
                onClick={closeImagePopup}
                className="w-full h-auto rounded-md object-cover cursor-pointer"
                src={scammer.img}
                alt="Scammer"
              />
            </div>
          </div>
        )}

        <button
          onClick={closePopup}
          className="bg-red-500 text-white px-12 py-3 rounded-md hover:bg-red-600 text-lg"
          style={{ fontSize: "16px" }}
        >
          {translations.close[language]}
        </button>
      </div>
    </div>
  );
}

Popup.propTypes = {
  scammer: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default Popup;
