import { Outlet } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "./App.css";

function App() {
  const toastOptions = {
    autoDismissTimeout: 3000,
    placement: "top-right",
    transitionDuration: 300,
    // Add the css prop to customize the style of the toast
    css: {
      fontSize: "18px", // Adjust the font size as needed
    },
  };

  return (
    <ToastProvider {...toastOptions}>
      <div className="App">
        <Outlet />
      </div>
    </ToastProvider>
  );
}

export default App;
