import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

function ProtectedRoute() {
  const token = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("tokenExpiration");

  const isTokenValid = () => {
    if (token && tokenExpiration) {
      const currentTime = new Date().getTime();
      const expirationTime = parseInt(tokenExpiration, 10);

      return currentTime < expirationTime;
    }
    return false;
  };

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ flex: "1", position: "fixed", top: 0, width: "100%" }}>
          {token && isTokenValid() && <Navbar />}
        </div>
        <div style={{ marginTop: 108, flex: "1" }}>
          {token && isTokenValid() ? <Outlet /> : <Navigate to="/login" />}
        </div>
        <footer style={{ marginTop: "auto" }}>
          {token && isTokenValid() && <Footer />}
        </footer>
      </div>
    </>
  );
}

export default ProtectedRoute;
