import React from "react";

function SiPrivacy() {
  return (
    <div class="bg-gray-100">
      <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">
          ScammersLK සඳහා රහස්‍යතා ප්‍රතිපත්තිය
        </h1>

        <p class="mb-4">අවසන් වරට යාවත්කාලීන කළේ: 1/15/2024</p>

        <p class="mb-4">
          ScammersLK භාවිතා කිරීම ගැන ඔබට ස්තුතියි. මෙම රහස්‍යතා ප්‍රතිපත්තිය අප
          රැස් කරන පුද්ගලික තොරතුරු වර්ග, එය භාවිතා කරන ආකාරය සහ තොරතුරු
          සම්බන්ධයෙන් ඔබේ තේරීම් ගෙනහැර දක්වයි. අපගේ වෙබ් අඩවිය භාවිතා කිරීමෙන්,
          ඔබ මෙම රහස්‍යතා ප්‍රතිපත්තියේ නියමයන්ට එකඟ වේ.
        </p>

        <h2 class="text-2xl font-bold mb-2">අපි එකතු කරන තොරතුරු</h2>

        <ul class="list-disc list-inside mb-4">
          <li className="text-xl"> නිර්නාමික පරිශීලක ඉදිරිපත් කිරීම්</li>
          <p>
            පරිශීලකයින්ට සැක සහිත වංචාකරුවන් පිළිබඳ තොරතුරු නිර්නාමිකව ඉදිරිපත්
            කළ හැකිය. මෙම තොරතුරුවල වංචාවට සම්බන්ධ අදාළ නම්, සම්බන්ධතා තොරතුරු
            සහ වෙනත් තොරතුරු ඇතුළත් විය හැකිය
          </p>
          <li className="text-xl"> ලොග් දත්ත</li>
          <p>
            ඔබ අපගේ වෙබ් අඩවියට පිවිසෙන සෑම විටම ඔබගේ බ්‍රවුසරය එවන තොරතුරු අපි
            රැස්කරමු. මෙයට ඔබගේ විද්‍යුත් තැපැල් ලිපිනය, බ්‍රවුසර වර්ගය,
            බ්‍රව්සර් අනුවාදය, ඔබ පිවිසෙන අපගේ වෙබ් අඩවියේ පිටු, වේලාව සහ ඔබගේ
            සංචාරයේ දිනය සහ අනෙකුත් සංඛ්‍යාලේඛන ඇතුළත් විය හැක.
          </p>
        </ul>

        <h2 class="text-2xl font-bold mb-2"> තොරතුරු භාවිතය</h2>

        <p class="mb-4">
          සැක සහිත වංචාකරුවන් හෙළිදරව් කිරීම සඳහා පරිශීලකයන් විසින් ඉදිරිපත් කරන
          ලද තොරතුරු අපගේ වෙබ් අඩවියේ ප්‍රසිද්ධියේ ලබා දෙනු ඇත. ඔවුන් ඉදිරිපත්
          කරන තොරතුරු වල නිරවද්‍යතාවය සහ නීත්‍යානුකූලභාවය සඳහා පලකරන්නන්
          සම්පූර්ණයෙන්ම වගකිව යුතුය.
        </p>

        <h2 class="text-2xl font-bold mb-2">ආරක්ෂාව</h2>

        <p class="mb-4">
          ඔබගේ තොරතුරු සුරක්ෂිත බව සහතික කිරීමට අපි කැපවී සිටිමු. අපි ඔබේ
          තොරතුරු ආරක්ෂා කිරීමට පියවර ක්‍රියාත්මක කරන අතරේ, අන්තර්ජාල
          සන්නිවේදනයේ ස්වභාවය නිසා අපට එහි නිරපේක්ෂ ආරක්ෂාව සහතික කළ නොහැක.
        </p>

        <h2 class="text-2xl font-bold mb-2">නීතිමය වියාචනය</h2>

        <p class="mb-4">
          වෙබ් අඩවියේ පළ කර ඇති තොරතුරුවල නිරවද්‍යතාවය, සම්පූර්ණත්වය හෝ
          නීත්‍යානුකූලභාවය සම්බන්ධයෙන් ScammersLK වගකිව යුතු නොවේ. අපගේ
          ප්‍රතිපත්ති උල්ලංඝනය කරන අන්තර්ගතය ඉවත් කිරීමට හෝ සංස්කරණය කිරීමට අපට
          අයිතියක් ඇත.
        </p>

        <h2 class="text-2xl font-bold mb-2">අපව අමතන්න</h2>

        <p class="mb-4">
          ඔබට මෙම රහස්‍යතා ප්‍රතිපත්තිය පිළිබඳව කිසියම් ප්‍රශ්නයක් හෝ ගැටලුවක්
          ඇත්නම්, කරුණාකර scammerslk@gmail.com අප හා සම්බන්ධ වන්න.
        </p>
      </div>
    </div>
  );
}

export default SiPrivacy;
