import React from "react";

function PrivacyPolicy() {
  return (
    <div class="bg-gray-100">
      <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Privacy Policy for ScammersLK</h1>

        <p class="mb-4">Last updated: 1/15/2024</p>

        <p class="mb-4">
          Thank you for using ScammersLK. This Privacy Policy outlines the types
          of personal information we collect, how it is used, and your choices
          regarding the information. By using our website, you agree to the
          terms of this Privacy Policy.
        </p>

        <h2 class="text-2xl font-bold mb-2">Information We Collect</h2>

        <ul class="list-disc list-inside mb-4">
          <li className="text-xl"> Anonymous User Submissions</li>
          <p>
            Users may submit information about suspected scammers anonymously.
            This information may include names, contact details, and other
            relevant details related to the alleged scam.
          </p>
          <li className="text-xl"> Log Data</li>
          <p>
            We collect information that your browser sends whenever you visit
            our website. This may include your Email address, browser type,
            browser version, the pages of our site that you visit, the time and
            date of your visit, and other statistics.
          </p>
        </ul>

        <h2 class="text-2xl font-bold mb-2"> Use of Information</h2>

        <p class="mb-4">
          The information submitted by users will be made publicly available on
          our website to expose suspected scammers. Users are solely responsible
          for the accuracy and legality of the information they submit.
        </p>

        <h2 class="text-2xl font-bold mb-2">Security</h2>

        <p class="mb-4">
          We are committed to ensuring that your information is secure. While we
          implement measures to protect your information, we cannot guarantee
          its absolute security due to the nature of internet communications.
        </p>

        <h2 class="text-2xl font-bold mb-2">Legal Disclaimer</h2>

        <p class="mb-4">
          ScammersLK is not responsible for the accuracy, completeness, or
          legality of information posted on the website. We reserve the right to
          remove or edit content that violates our policies.
        </p>

        <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

        <p class="mb-4">
          If you have any questions or concerns about this Privacy Policy,
          please contact us scammerslk@gmail.com.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
