import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { txtdb } from "../../firebase";
import Popup from "../../components/ScammerPopup";
import "./loader.css";

function Home() {
  const [scammers, setScammers] = useState([]);
  const [smallScreen, setSmallScreen] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [selectedScammer, setSelectedScammer] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const language = localStorage.getItem("language");
  const [loading, setLoading] = useState(true);
  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const scammersCollection = collection(txtdb, "scammers");
      const snapshot = await getDocs(scammersCollection);
      const scammersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort scammersData based on datetime in descending order (newest first)
      scammersData.sort((a, b) => {
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);
        return dateB - dateA;
      });

      setScammers(scammersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResize = () => {
    setSmallScreen(window.matchMedia("(max-width: 1000px)").matches);
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openPopup = (scammer) => {
    setSelectedScammer(scammer);
    setPopupIsOpen(true);
  };

  const closePopup = () => {
    setPopupIsOpen(false);
  };

  // Filter scammers based on the search keyword
  const filteredScammers = scammers.filter(
    (scammer) =>
      scammer.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      scammer.contact.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      scammer.description.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const translations = {
    readmore: {
      en: "Read More",
      si: "තව කියවන්න",
    },
    loading: {
      en: "Loading...",
      si: "රැදී සිටින්න...",
    },
  };

  return (
    <div>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          {/* Background Blur Container */}
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 blur"></div>

          {/* Loader */}
          <div className="relative z-15">
            <div className="spinner w-16 h-16"></div>
            <h2 className="text-xl font-bold">
              {translations.loading[language]}
            </h2>
          </div>
        </div>
      )}

      <center>
        {!loading && (
          <div className="fixed w-full px-4 mb-5 py-2 bg-white z-0">
            <input
              type="text"
              placeholder="Search scammers..."
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              className="container max-w-xl px-4 my-auto py-2 border border-gray-500 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
        )}
      </center>
      <br />
      <br />
      <br />

      {filteredScammers.map((scammer) => (
        <div key={scammer.id}>
          <div
            className={
              smallScreen
                ? "container mx-auto max-w-sm p-4 bg-white border border-gray-600 rounded-xl shadow dark:bg-gray-900 dark:border-gray-800 m-5"
                : "container mx-auto max-w-xl p-4 bg-white border border-gray-600 rounded-xl shadow dark:bg-gray-900 dark:border-gray-800 m-5"
            }
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {scammer.name}
            </h5>
            <h3 className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {scammer.contact}
            </h3>

            <p
              className="mb-3 font-normal text-gray-700 dark:text-gray-400"
              style={{ overflowWrap: "break-word" }}
            >
              {truncateText(scammer.description, 200)}
            </p>

            <button
              onClick={() => openPopup(scammer)}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {translations.readmore[language]}
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}

      {popupIsOpen && (
        <Popup scammer={selectedScammer} closePopup={closePopup} />
      )}
    </div>
  );
}

export default Home;
