import React from "react";

function AboutUs() {
  return (
    <div class="bg-gray-100">
      <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">About ScammersLK</h1>

        <p class="mb-4">Last updated: 1/15/2024</p>

        <p class="mb-4">
          Welcome to ScammersLK, your community-driven platform dedicated to
          exposing and sharing information about suspected scammers. Our mission
          is to empower individuals by providing a space where they can
          anonymously contribute and access information related to potential
          scams.
        </p>

        <h2 class="text-2xl font-bold mb-2"> Our Purpose</h2>

        <p class="mb-4">
          ScammersLK is a platform created for users to collaboratively work
          together to bring awareness to and uncover potential scams. We believe
          in the strength of community-driven efforts to protect one another
          from fraudulent activities.
        </p>

        <h2 class="text-2xl font-bold mb-2">How it Works?</h2>

        <p class="mb-4">
          Users can anonymously submit information about suspected scammers,
          including names, contact details, and other relevant information. This
          information is then made publicly available on our website, allowing
          others to be informed and vigilant against potential scams.
        </p>

        <h2 class="text-2xl font-bold mb-2">Community-Driven Transparency</h2>

        <p class="mb-4">
          ScammersLK operates on the principle of transparency and community
          support. We encourage users to contribute responsibly, ensuring the
          accuracy and legality of the information they submit. By sharing
          information collectively, we aim to create a safer online environment
          for everyone.
        </p>

        <h2 class="text-2xl font-bold mb-2">Legal Disclaimer</h2>

        <p class="mb-4">
          While we provide a platform for users to share information, it's
          important to note that ScammersLK does not take responsibility for the
          accuracy, completeness, or legality of the content posted on the
          website. We reserve the right to remove or edit content that violates
          our policies.
        </p>

        <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

        <p class="mb-4">
          Your feedback and involvement are crucial to the success of our
          platform. If you have any questions, concerns, or suggestions, please
          feel free to reach out to us at scammerslk@gmail.com.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
