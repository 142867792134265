import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import InsertDetails from "./pages/InsertDetails";
import Navbar from "./components/Navbar";
import MyScams from "./pages/MyScams";
import PrivacyPolicy from "./pages/PrivacyPolicy/Index";
import AboutUs from "./pages/AboutUs";
import SiPrivacy from "./pages/Si-Privacy/Index";
import SiAboutUs from "./pages/Si-AboutUs";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/login" element={<Login />} />
      <Route path="/nav" element={<Navbar />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" index element={<Home />} />
        <Route path="/insert" element={<InsertDetails />} />
        <Route path="/myscam" element={<MyScams />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/sinhala/privacy" element={<SiPrivacy />} />
        <Route path="/sinhala/about" element={<SiAboutUs />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

reportWebVitals();
