import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { txtdb } from "../../firebase";
import DeleteIcon from "../../images/delete-icon.png";

function MyScams() {
  const [scammers, setScammers] = useState([]);
  const [smallScreen, setSmallScreen] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [confirmationModal, setConfirmationModal] = useState(null); // New state for confirmation modal

  const user = JSON.parse(localStorage.getItem("user"));
  const userEmail = user ? user.email : null;
  const language = localStorage.getItem("language");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (userEmail) {
        const q = query(
          collection(txtdb, "scammers"),
          where("email", "==", userEmail)
        );
        const snapshot = await getDocs(q);

        const scammersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setScammers(scammersData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResize = () => {
    setSmallScreen(window.matchMedia("(max-width: 768px)").matches);
  };

  const handleDeleteConfirmation = (scammerId) => {
    setConfirmationModal({
      id: scammerId,
      message: translations.message[language],
    });
  };

  const handleDeleteScam = async () => {
    try {
      if (confirmationModal && confirmationModal.id) {
        const scammerRef = doc(txtdb, "scammers", confirmationModal.id);
        await deleteDoc(scammerRef);
        setConfirmationModal(null);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting scam:", error);
    }
  };

  const translations = {
    delete: {
      en: "Delete",
      si: "මකන්න",
    },
    message: {
      en: "Are you sure you want to delete this scam?",
      si: "ඔබට මෙම වංචාව මැකීමට අවශ්‍ය බව විශ්වාසද?",
    },
    yes: {
      en: "Yes",
      si: "ඔව්",
    },
    no: {
      en: "No",
      si: "නැත",
    },
    loading: {
      en: "Loading...",
      si: "රැදී සිටින්න...",
    },
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Make sure to include an empty dependency array for useEffect

  return (
    <div>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          {/* Background Blur Container */}
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 blur"></div>

          {/* Loader */}
          <div className="relative z-15">
            <div className="spinner w-16 h-16"></div>
            <h2 className="text-xl font-bold">
              {translations.loading[language]}
            </h2>
          </div>
        </div>
      )}
      {scammers.map((scammer) => (
        <div key={scammer.id}>
          <div
            className={
              smallScreen
                ? "container mx-auto max-w-sm p-4 bg-white border border-gray-600 rounded-xl shadow dark:bg-gray-900 dark:border-gray-800 m-5"
                : "container mx-auto max-w-xl p-4 bg-white border border-gray-600 rounded-xl shadow dark:bg-gray-900 dark:border-gray-800 m-5"
            }
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {scammer.name}
            </h5>
            <h3 className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              {scammer.contact}
            </h3>

            <p
              className="mb-3 font-normal text-gray-700 dark:text-gray-400"
              style={{ overflowWrap: "break-word" }}
            >
              {scammer.description}
            </p>
            <button
              onClick={() => handleDeleteConfirmation(scammer.id)}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-red-500 dark:hover:bg-red-800 dark:focus:bg-red-500"
            >
              {translations.delete[language]}
              <img
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                src={DeleteIcon}
                alt="Delete Icon"
              />
            </button>
          </div>
        </div>
      ))}
      {/* Confirmation Modal */}
      {confirmationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md">
            <p>{confirmationModal.message}</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setConfirmationModal(null)}
                className="mr-2 px-3 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-700"
              >
                {translations.no[language]}
              </button>
              <button
                onClick={handleDeleteScam}
                className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
              >
                {translations.yes[language]}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyScams;
