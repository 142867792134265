import React, { useState } from "react";
import { imgDB, txtdb } from "../../firebase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { useToasts } from "react-toast-notifications";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

function InsertDetails() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const email = user.email;
  const { addToast } = useToasts();
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ssXXX");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleUpload = async (e) => {
    try {
      setLoading(true);
      const imgsRef = ref(imgDB, `imgs/${v4()}`);
      const snapshot = await uploadBytes(imgsRef, e.target.files[0]);
      const downloadURL = await getDownloadURL(snapshot.ref);
      setImg(downloadURL);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () => {
    const language = localStorage.getItem("language");

    const alerts = {
      allFieldsRequired: {
        en: "All fields are required.",
        si: "සියලුම ක්ෂේත්‍ර සම්පූර්ණ කිරීමට අවශ්‍යයි.",
      },
      uploadProofImage: {
        en: "Please Upload Proof Image.",
        si: "කරුණාකර සනාථ කිරීමට රූපයක් උඩුගත කරන්න.",
      },
      shortDescription: {
        en: "Description should be at least 50 characters long.",
        si: "විස්තරය අවශ්‍ය 50 අකුරු දක්වා ඇති බවකි.",
      },
      waitImageUpload: {
        en: "Please wait till the image is uploaded.",
        si: "කරුණාකර පින්තූරය උඩුගත කරන තෙක් රැඳී සිටින්න.",
      },
      dataAdded: {
        en: "Success, information added",
        si: "සාර්ථකයි, තොරතුරු එකතු කරන ලදී",
      },
    };

    if (!name || !contact || !description || !type) {
      addToast(
        alerts.allFieldsRequired[language] || alerts.allFieldsRequired.en,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (!img) {
      addToast(
        alerts.uploadProofImage[language] || alerts.uploadProofImage.en,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (description.length < 50) {
      addToast(
        alerts.shortDescription[language] || alerts.shortDescription.en,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (img.length < 1) {
      addToast(alerts.waitImageUpload[language] || alerts.waitImageUpload.en, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setLoading(true);
      try {
        const valRef = collection(txtdb, "scammers");
        await addDoc(valRef, {
          name,
          contact,
          description,
          type,
          img,
          email,
          datetime: formattedDate,
        });

        addToast(alerts.dataAdded[language] || alerts.dataAdded.en, {
          appearance: "success",
          autoDismiss: true,
        });

        setTimeout(() => {
          setLoading(false);
          navigate("/");
        }, 500);
      } catch (error) {
        console.error("Error adding document:", error);
      }
    }
  };
  const language = localStorage.getItem("language");

  const getLabelText = (key) => {
    const labels = {
      name: {
        en: "Scammer Name/Company Name",
        si: "වංචාකාරුගේ නම/සමාගමේ නම",
      },
      contact: {
        en: "Scammer's Phone/Email/Website",
        si: "වංචාකාරුගේ දුරකථන අංකය/ඊමේල්/වෙබ් අඩවිය",
      },
      type: {
        en: "Scam Type(Pyramid, Ponzi, Online Money Scams, Thieves and Etc..)",
        si: "වංචාවේ ස්වභාවය (පිරම්ඩ, පොන්සි, අන්තර්ජාල මුදල් වංචා, සහ තවත් එවැනි ආකාර)",
      },
      description: {
        en: "Description",
        si: "විස්තර",
      },
      descriptioninfo: {
        en: "Describe the scam in detail. Include information on how to identify the scam, methods scammers use to contact you, and what they typically say. Also, provide tips on how to avoid falling victim to the scam and etc.",
        si: "වංචාව විස්තරාත්මකව විස්තර කරන්න. වංචාව හඳුනා ගන්නේ කෙසේද, වංචාකරුවන් ඔබව සම්බන්ධ කර ගැනීමට භාවිතා කරන ක්‍රම සහ ඔවුන් සාමාන්‍යයෙන් පවසන දේ පිළිබඳ තොරතුරු ඇතුළත් කරන්න. එසේම, වංචාවට ගොදුරු වීම වළක්වා ගන්නේ කෙසේද සහ යනාදිය පිළිබඳ උපදෙස් ලබා දෙන්න",
      },
      proof: {
        en: "Proof(Screenshot of the chat, email, or website etc..)",
        si: "සනාථ කිරීම (Chat, විද්‍යුත් තැපෑල, හෝ වෙබ් අඩවිය ආදියෙහි Screenshot..)",
      },
      submit: {
        en: "Submit",
        si: "එකතු කරන්න",
      },
    };

    return labels[key] ? labels[key][language] || labels[key].en : key;
  };

  const translations = {
    loading: {
      en: "Uploading...",
      si: "උඩුගත කරමින් පවතී...",
    },
  };

  return (
    <div>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          {/* Background Blur Container */}
          <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 blur"></div>

          {/* Loader */}
          <div className="relative z-15">
            <div className="spinner w-16 h-16"></div>
            <h2 className="text-xl font-bold">
              {translations.loading[language]}
            </h2>
          </div>
        </div>
      )}
      <div className="container mx-auto p-4">
        <div className="max-w-md mx-auto bg-white p-6 rounded-md shadow-md">
          <form>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-gray-600"
              >
                {getLabelText("name")}
              </label>
              <input
                type="text"
                id="name"
                className="mt-1 p-2 w-full border rounded-md border-gray-900"
                aria-describedby="nameHelp"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="contact"
                className="block text-sm font-semibold text-gray-600"
              >
                {getLabelText("contact")}
              </label>
              <input
                type="text"
                id="contact"
                className="mt-1 p-2 w-full border rounded-md border-gray-900"
                aria-describedby="contactHelp"
                onChange={(e) => setContact(e.target.value)}
                placeholder={getLabelText("contact")}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="type"
                className="block text-sm font-semibold text-gray-600"
              >
                {getLabelText("type")}
              </label>
              <input
                type="text"
                id="type"
                className="mt-1 p-2 w-full border rounded-md border-gray-900"
                aria-describedby="contactHelp"
                onChange={(e) => setType(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-semibold text-gray-600"
              >
                {getLabelText("description")}
              </label>
              <textarea
                id="description"
                className="mt-1 p-2 w-full border rounded-md border-gray-900"
                rows="4"
                onChange={(e) => setDescription(e.target.value)}
                placeholder={getLabelText("descriptioninfo")}
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="formFile"
                className="block text-sm font-semibold text-gray-600"
              >
                {getLabelText("proof")}
              </label>
              <input
                type="file"
                id="formFile"
                className="mt-1 p-2 w-full border rounded-md"
                onChange={handleUpload}
              />
            </div>
            <center>
              <button
                type="button"
                className="bg-blue-500 text-white w-1/2 px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={handleClick}
              >
                {getLabelText("submit")}
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
}

export default InsertDetails;
